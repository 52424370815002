.gmc__partners-slider_image {
    width: 120px;
    height: 120px;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.gmc__partners-slider_image a {
    display: inline-block;
}

.gmc__partners-slider_image img {
    width: 100%;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}