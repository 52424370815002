.default__modal_title {
    font-size: 24px;
    line-height: 30px;
    color: #000624;
    margin-bottom: 2rem;
}


.form__row {
    padding: 1rem 0;
}

.request__model__form label {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text-primary);
    margin-bottom: .4rem;
}
.request__model__form input[type="text"] {
    display: block;
    width: 100%;
    background: #F7F9FC;
    border: 1px solid #EDF2F7;
    border-radius: 4px;
    padding: 1rem;
    color: #3C5366;
    outline: none;
}

.form__buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem;
}

.form__buttons .button__primary {
    padding: 1rem 3rem !important;
}

@media screen and (max-width: 1359px) {
  
}


@media screen and (max-width: 490px) {
  
}