.gmc__cta-content_buttons {
    display: flex;
    gap: 1rem;
}

.gmc__cta-content_buttons a {
    display: block;
    padding: 0;
    
}

.gmc__cta-content_buttons img {
    width: 100%;
    box-shadow: 0px 0 10px rgba(0,0,0,.2);
}