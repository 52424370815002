.gmc__screenshot-slider_image {
   display: flex !important;
   justify-content: center;
   align-items: center;
   outline: none;
   padding-right: 30px;
}

.gmc__screenshot-slider_image img {
   width: 100%  !important;
}


@media screen and (max-width: 1050px) {
    
}