.accordation {
    position: relative;
    background: #fff;
    box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
    border-radius: 18px;
}

.accordation__title {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 1rem;
}

.accordation__title h4 {
    font-size: 21px;
    line-height: 28px;

}

.accordation__copy {
    transition: height 0.4s ease-in-out;
    overflow: hidden;
}

.accordation__copy p {
    padding: 2rem;
    padding-top: 0;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-text-secondary);
}

.accordation.active .accordation__copy {
    height: 0;
}

@media screen and (max-width: 1050px) {
    
    
    
}

@media screen and (max-width: 490px) {
    .accordation__title h4 {
        font-size: 16px;
        line-height: 28px;
    }

    .accordation__copy {
        font-size: 12px;
        line-height: 20px;
    }
}
