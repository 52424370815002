.gmc__partners {
   display: flex;
   flex-direction: column;
}



.gmc__partners h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 43px;
    color: var(--color-text-primary);
    letter-spacing: 0.2px;
    margin: 1.3rem 0;
}

.gmc__partners-images {
    margin-top: 1rem;
}

@media screen and (max-width: 650px) {
    .gmc__partners br {
        display: none;
    }
    
    
}

@media screen and (max-width: 490px) {
    .gmc__partners h2 {
        font-size: 21px;
        line-height: 32px;
    }

    .gmc__partners-subtitle {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 2px;
    }

    .gmc__partners-images {
        margin-top: 0rem;
    }
}