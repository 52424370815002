.gmc__header {
    display: flex;
    flex-direction: row;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 12rem !important;

}

.gmc__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.gmc__header-content h1 {
    font-family: var(--font-family-headers);
    font-weight: 800;
    font-size: 64px;
    line-height: 80px;
    color: var(--color-text-primary);
}

.gmc__header-content p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: var(--color-text-secondary);
    margin: 1.5rem 0;
}

.gmc__header-content a {
    padding: 1.2rem 2rem;
}

.gmc__header-image {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(0deg, #F7F7FB 0%, rgba(243, 247, 253, 0) 100%);
    border-radius:0px 0px 40px 40px ;
    
}

.gmc__header-image-desc-1, .gmc__header-image-desc-2 {
    width: 231px;
    position: absolute;
    
    padding: 1rem;
    background: radial-gradient(100% 100% at 0% 0%, rgba(223, 227, 239, 0.104) 0%, rgba(255, 255, 255, 0.104) 0.01%, rgba(255, 255, 255, 0.176) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    border: 3px solid rgba(104, 201, 244, 0.102);
    backdrop-filter: blur(42px);
    border-radius: 8px;
}

.gmc__header-image-desc-1 {
    top: 20px;
    right: calc(-231px /2);
}

.gmc__header-image-desc-2 {
    bottom: 20px;
    left: calc(-231px /2);
}

.gmc__header-image-desc-1 p, .gmc__header-image-desc-2 p {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: var(--color-text-primary);
}

.gmc__header-image img {
    width: 60%;
}


@media screen and (max-width: 1050px) {
    .gmc__header {
        flex-direction: column;
    }

    .gmc__header-content {
        margin: 0 0 3rem;
    }
    
}

@media screen and (max-width: 650px) {
    .gmc__header {
        flex-direction: column;
    }

    .gmc__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gmc__header-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .gmc__header-image img {
        width: 70%
    }

    .gmc__header-image-desc-1, .gmc__header-image-desc-2 {
        display: none;
    }
    
}

@media screen and (max-width: 490px) {
    .gmc__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gmc__header-content p {
        font-size: 14px;
        line-height: 20px;
    }

    .gmc__header-content a {
        font-size: 12px;
        line-height: 24px;
        padding: 1rem;
    }

    
}