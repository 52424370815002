.gmc__donations-background {
    background: linear-gradient(0deg, rgba(25, 118, 210, 0) -4.42%, rgba(25, 118, 210, 0.08) 100%);
    padding-top: 8rem !important;
}

.gmc__donations__header {
    display:  flex;
    align-items: center;
    gap: 10rem;
}

.gmc__donations__header .section__heading {
    font-size: 40px;
    line-height: 56px;
}

.section__quote_desc {
   margin-top: 4em;
}

.gmc__donations__header .section__desc {
    font-size: 18px;
    line-height: 32px;
    margin: 1.5rem 0;
}

@media screen and (max-width: 1050px) {
    .gmc__donations__header {
        flex-direction: column;
        gap: 2rem;
    }
}

@media screen and (max-width: 650px) {
    .gmc__donations__header {
        display: flex;
        flex-direction: column;
        gap: 2;
    }
}

@media screen and (max-width: 490px) {
    .gmc__donations__header {
        flex-direction: column;
        gap: 2rem;
    }
    
}