.gmc__footer {
    background: #2B2D2F;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.gmc__footer-wrapper {
    background: #2B2D2F;
    display: flex;
    gap: 2rem;
}

.gmc__footer h3 {
    color: #fff;
    margin-bottom: 2rem;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
}

.gmc__footer ul {
    list-style: none;
}

.gmc__footer ul li a {
    display: block;
    color: #fff;
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}

.gmc__footer ul li a:hover, .gmc__footer-copy_links a:hover {
    color: var(--color-primary);
}

.gmc__footer ul li a:hover svg {
    fill: var(--color-primary);
}

.gmc__footer p {
    color: #fff;
    font-size: 16px;
    line-height: 27px;
}

.gmc__footer-company {
    flex: 2;
}

.gmc__footer-links, .gmc__footer-socials {
    flex: 1;
}

.gmc__footer-company p {
    margin-bottom: 2rem;
}

.gmc__footer-socials ul {
    display: flex;
    gap: 2rem;
}

.gmc__footer-socials ul a {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(115, 122, 145, .18);
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.gmc__footer-copy {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    border-top: 1px solid rgba(255,255,255,.2);
    padding-top: 1.3rem;
    margin-top: 3rem;
}

.gmc__footer-copy_links {
    display: flex;
    gap: 3rem;
}

.gmc__footer-copy_links a, .gmc__footer-copy_text p {
    color: #B9B7C8;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
}


@media screen and (max-width: 1050px) {
    .gmc__footer-wrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 650px) {
    .gmc__footer-copy {
        flex-direction: column;
    }
}
