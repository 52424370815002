.gmc__testimonial {
    background-repeat: no-repeat, repeat !important;
    background-position: 50% 200%, center !important;
    background-size: 100%, 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

}

.gmc__testimonial-header {
    flex-basis: 50%;
}

.gmc__testimonial-video {
    flex-basis: 50%;
   
}

.gmc__testimonial-header h2 {
    font-size: 40px;
    line-height: 48px;
    color: #052049;
}

.gmc__testimonial-header p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: var(--color-text-secondary);
    margin: 1rem 0;
}

.gmc__testimonial-video-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
}

.gmc__testimonial .video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .gmc__testimonial {
        flex-direction: column;
        background-position: 40% 30% !important;
        background-size: contain !important;
        gap: 20px;
    }
    .gmc__testimonial * {
        text-align: left !important;
    }

    
    .gmc__testimonial-header p br{
        display: none;
    }

    .gmc__testimonial-video {
        width: 100%;
    }

   
}

@media screen and (max-width: 650px) {
    .gmc__testimonial {
       
        flex-direction: column;
    }
    .gmc__testimonial-header h2 {
        font-size: 27px;
        line-height: 32px;
    }

    .gmc__testimonial-header p {
        font-size: 16px;
        line-height: 24px;
    }

   
}