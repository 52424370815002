.gmc__howto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20rem;
}

.gmc__howto-content {
    flex: 1;
}

.gmc__howto-content h2 {
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    
}

.gmc__howto-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    margin: 2.2rem 0;
    color: var(--color-text-primary);
}

.gmc__howto-instructions {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.gmc__howto-instructions_item {
    display: flex;
    align-items: flex-start;
    background: #fff;
    box-shadow: 0px 15px 50px rgba(21, 37, 72, 0.05);
    border-radius: 12px;
    padding: 2rem;
}

.gmc__howto-instructions_item-image {
    margin-right: 1rem;
}

.gmc__howto-instructions_item-image svg {
    width: 69px;
}

.gmc__howto-instructions_item-content h4 {
    font-size: 20px;
    line-height: 28px;
}

.gmc__howto-instructions_item-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-text-secondary);
    margin: .3rem 0;
}

@media screen and (max-width: 1632px) {
    .gmc__howto {
        gap: 10rem;
    }
}

@media screen and (max-width: 1348px) {
    .gmc__howto {
        gap: 4rem;
        flex-direction: column;
    }
}

@media screen and (max-width: 460px) {
    .gmc__howto-content h2 {
        font-size: 27px;
        line-height: 32px;
    }

    .gmc__howto-content p {
        font-size: 14px;
        line-height: 20px;
        margin: 1.5rem 0;
    }

    .gmc__howto-instructions_item {
        flex-direction: column;
        padding: 1.4rem;
    }

    .gmc__howto-instructions_item-content h4 {
        font-size: 21px;
        line-height: 24px;
        margin: 1rem 0;
    }

    .gmc__howto-instructions_item-content p {
        font-size: 14px;
        line-height: 20px;
       
    }
}