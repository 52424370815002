.admin__modal {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 624px;
    overflow: auto;
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(232, 237, 250, 0.2);
}


.admin__modal .close__button {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #F5F5F7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .4s;
}

.admin__modal .close__button:hover {
    background: #e4e4e7;
}

.admin__modal .close__button svg {
    position: relative;
    z-index: 100;
}


@media screen and (max-width: 1359px) {
    .admin__modal {
        width: calc(100% - 40px);
        max-height: calc(100vh - 50px);
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        transform: translate(-50%, -50%);
        
    }
}


@media screen and (max-width: 490px) {

}