.gmc__employers-background {
    background: linear-gradient(0deg, rgba(25, 118, 210, 0) -4.42%, rgba(25, 118, 210, 0.08) 100%);
    padding-top: 8rem !important;
}

.gmc__employers__header {
    display:  flex;
    align-items: center;
    gap: 10rem;
}

.gmc__employers__header .section__heading {
    font-size: 40px;
    line-height: 56px;
}

.section__quote_desc {
   margin-top: 4em;
}

.gmc__employers__header .section__desc {
    font-size: 18px;
    line-height: 32px;
    margin: 1.5rem 0;
}

@media screen and (max-width: 1050px) {
    .gmc__employers__header {
        flex-direction: column;
        gap: 2rem;
    }
}

@media screen and (max-width: 650px) {
    .gmc__employers__header {
        display: flex;
        flex-direction: column;
        gap: 2;
    }
}

@media screen and (max-width: 490px) {
    .gmc__employers__header {
        flex-direction: column;
        gap: 2rem;
    }
    
}



/*how to styles*/
.gmc__howto.dark {
    padding: 6rem 6rem;
    border-radius: 16px;
}

.gmc__howto.dark .gmc__howto-content {
    flex: 1;
}

.gmc__howto.dark .gmc__howto-content h2 {
   color: #fff;
    
}

.gmc__howto.dark .gmc__howto-content p {
    color: #fff;
}

.gmc__howto.dark {
    gap: 5rem;
}

@media screen and (max-width: 1632px) {
    
}

@media screen and (max-width: 1348px) {
    .gmc__howto.dark {
       padding: 2rem;
    }
}

@media screen and (max-width: 460px) {
    .gmc__howto.dark .gmc__howto-content h2 {
        font-size: 27px;
        line-height: 32px;
    }

    .gmc__howto.dark .gmc__howto-content p {
        font-size: 14px;
        line-height: 20px;
        margin: 1.5rem 0;
    }

    .gmc__howto.dark .gmc__howto-instructions_item {
        flex-direction: column;
        padding: 1.4rem;
    }

    .gmc__howto.dark .gmc__howto-instructions_item-content h4 {
        font-size: 21px;
        line-height: 24px;
        margin: 1rem 0;
    }

    .gmc__howto.dark .gmc__howto-instructions_item-content p {
        font-size: 14px;
        line-height: 20px;
       
    }
}