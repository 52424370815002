.gmc__caregiver-reviews {
    background-repeat: no-repeat, repeat !important;
    background-position: 50% 200%, center !important;
    background-size: 100%, 100% !important;
    padding-bottom: 0 !important;
}

.gmc__caregiver-reviews-header h2 {
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    color: #052049;
}

.gmc__caregiver-reviews-header p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: var(--color-text-secondary);
    margin: 1rem 0;
}

.gmc__caregiver-reviews_reviews {
    text-align: center;
}

.gmc__caregiver-reviews_review {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat !important;
    background-position: center 10% !important;
    max-width: 450px !important;
    background: #fff;
    padding: 2rem;
    box-shadow: 0px -1px 45px rgba(59, 59, 59, 0.160784);
    border-radius: 12px;
}

.gmc__caregiver-reviews_review-comment {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
}

.gmc__caregiver-reviews_review-author {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.528px;
    color: #3885B5;
    margin-top: 1.6rem;
}

.gmc__caregiver-reviews_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background: #2B2D2F;
    border-radius: 24px 24px 0px 0px;
    margin-top: 7rem;
    gap: 2rem;
}

.gmc__caregiver-reviews_footer-content {
   padding-left: 2rem;
}

.gmc__caregiver-reviews_footer-link {
    padding-right: 2rem;
}

.gmc__caregiver-reviews_footer-link a {
    padding: 1rem 3.2rem;
    background: var(--color-primary);
    color: #fff;
    box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
    border-radius: 10px;
}

.gmc__caregiver-reviews_footer-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #EEEFF2;
}

@media screen and (max-width: 1050px) {
    .gmc__caregiver-reviews {
        background-position: 50% 100%, center !important;
    }
    .gmc__caregiver-reviews * {
        text-align: left !important;
    }

    
    .gmc__caregiver-reviews-header p br{
        display: none;
    }
    .gmc__caregiver-reviews_reviews {
        flex-direction: column;
        
    }
    
    .gmc__caregiver-reviews_review {
        width: 100% !important;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 2rem 1rem; 
    }

    .gmc__caregiver-reviews_footer {
        padding: 1rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }

    .gmc__caregiver-reviews_footer-content {
        padding-left: 0;
     }
     
     .gmc__caregiver-reviews_footer-link {
         padding-right: 0;
     }
}

@media screen and (max-width: 650px) {
    .gmc__caregiver-reviews {
        background-position: 50% 10%, center !important;
    }
    .gmc__caregiver-reviews-header h2 {
        font-size: 27px;
        line-height: 32px;
    }

    .gmc__caregiver-reviews-header p {
        font-size: 16px;
        line-height: 24px;
    }

    .gmc__caregiver-reviews_footer {
        align-items: flex-start;
        gap: 1rem;
        margin-top: 4rem;
    }

    .gmc__caregiver-reviews_footer-content p br {
        display: none;
    }

    .gmc__caregiver-reviews_footer-content p {
        font-size: 14px;
        line-height: 24px;
    }
   

    .gmc__caregiver-reviews_footer-link a {
        padding: 1rem 3rem;
        font-size: 14px;
    }
}