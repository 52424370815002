.gmc__pricing_slider {
    background: #354B5E;
    text-align: center;
    color: #fff;
}

.gmc__pricing_slider .header {
    max-width: 760px;
    margin: 0 auto;
    text-align: center;
}

.gmc__pricing_slider h2 {
    font-size: 40px;
    line-height: 48px;
    color: #fff;
}

.gmc__pricing_slider p {
    
    font-weight: 300;
    font-size: 18px;
    line-height: 32px;
    margin: 1.2rem 0rem;
    color: #fff;
}

.gmc__pricing_slider .slider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
    margin-top: 70px;
    max-width: 860px;
}

.gmc__pricing_slider .price-box {
    background: #fff;
    border-radius: 8px;
    padding: 16px 20px;
    color: #3885B5;
    font-size: 32px;
    font-weight: bold;
    font-family: var(--font-family-headers);
}

.gmc__pricing_slider .price-box  span {
    font-size: 16px;
    color: #3C5366;
    font-weight: 400;
    font-family: var(--font-family);
}
.MuiSlider-thumb{
    color: #E5C14B;
  }
  .MuiSlider-rail{
    color:#EEEFF2;
  }
  .MuiSlider-track{
    color: #02B453;
  }
  .MuiSlider-mark{
    width: 10px !important;
    height: 10px !important;
    border-radius: 10px !important;
    color: #fff;
  }
  .MuiSlider-markLabel {
    color: #fff !important;
  }

  .gmc__pricing_slider .switch {
    margin-top: 60px;
  }


  @media screen and (max-width: 1050px) {
   

    
}

@media screen and (max-width: 490px) {
    .gmc__pricing_slider h2  {
        font-size: 27px;
        line-height: 32px;
    }

   
    .gmc__pricing_slider p {
        font-size: 16px;
        line-height: 24px;
    }

    .MuiSlider-markLabel {
        font-size: 8px !important;
      }

      .gmc__pricing_slider .price-box {
        font-size: 18px;
      }

      .gmc__pricing_slider .slider {
        margin-top: 20px;
      }

      .gmc__pricing_slider .switch {
        margin-top: 30px;
      }
    
}



