.gmc__whatsnew {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: #40A1CC;
    padding-right: 0 !important;
    gap: 5rem;
}

.gmc__whatsnew-content {
    flex: 1;
}

.gmc__whatsnew-content h1 {
    font-size: 40px;
    line-height: 48px;
    color: #FFFFFF;
}

.gmc__whatsnew-content p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 2.4rem 0;
}

.gmc__whatsnew-screenshots {
    width: 60%;
    padding: 5rem 0;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 24px 0px 0px 24px;
}

.gmc__whatsnew-content_slider-arrows_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
    gap: 3rem;
}

.gmc__whatsnew-content_slider-arrows_wrapper p {
    font-size: 16px;
    line-height: 20px;
}

.gmc__whatsnew-content_slider-arrows {
    display: flex;
    gap: 1rem;
}

.gmc__whatsnew-content_slider-arrows button {
    display: flex;
    justify-content: center;
    align-items: center;
   
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: transparent;
    cursor: pointer;
}

.gmc__whatsnew-content_slider-arrows button.disabled {
    border: 2px solid #ffffffa3;
}

.gmc__whatsnew-content_slider-arrows_mobile {
    display: none;
}

@media screen and (max-width: 1050px) {
    .gmc__whatsnew-content_slider-arrows_wrapper {
        display: none;
    }

    .gmc__whatsnew-content_slider-arrows_mobile {
        display: flex;
    }
    .gmc__whatsnew {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }

    .gmc__whatsnew-screenshots {
        width: 100%;
        background: transparent;
    }
}

@media screen and (max-width: 490px) {
    .gmc__whatsnew-content h1 {
        font-size: 27px;
        line-height: 32px;
    }

    .gmc__whatsnew-content p {
        font-size: 16px;
        line-height: 24px;
    }
}