.gmc__features {
    background: linear-gradient(180deg, #F7F7FB 0%, rgba(247, 247, 251, 0) 16.05%);
}

.gmc__features h2 {
    font-size: 40px;
    line-height: 48px;
}

.gmc__features p {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: var(--color-text-secondary);
    margin: 1.2rem 0rem;
}

.gmc__features-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.gmc__features-list-item {
    padding-top: 3.5rem;
    padding-right: 6rem;
    flex-basis: 400px;
}

.gmc__features-list-item_image {
    width: 55px;
    margin-bottom: 0.5rem;
}

.gmc__features-list-item h4 {
    font-size: 24px;
    line-height: 32px;
    margin: 0.5rem 0;
}

.gmc__features-list-item p {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: var(--color-text-secondary);
}

.gmc__features-list-item a {
    color: var(--color-primary);
}

.gmc__features-list-item a:hover {
    color: var(--color-text-primary);
}

@media screen and (max-width: 1050px) {
    .gmc__features-list {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .gmc__features-list-item {
        padding-right: 0;
        flex-basis: 100%;
    }

    
}

/* @media screen and (max-width: 650px) {
   

} */

@media screen and (max-width: 490px) {
    .gmc__features h2  {
        font-size: 27px;
        line-height: 32px;
    }

    .gmc__features h4 {
        font-size: 21px;
        line-height: 28px;
    }

    .gmc__features p {
        font-size: 16px;
        line-height: 24px;
    }
    .gmc__features-list-item_image img {
        width: 48px; 
    }
}