.gmc__navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 10rem;
}

.gmc__navbar.sticky-bar {
    padding: 1.5rem 10rem;
    left: 0;
    margin: auto;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 10px 40px rgb(25 25 25 / 10%);
    z-index: 1000;
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
    background: #fff;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.gmc__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gmc__navbar-links_logo {
    flex: 1;
    margin-right: 2rem;
}

.gmc__navbar-links_logo img {
    width: 56px;
}

.gmc__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gmc__navbar-sign {
    display: flex;
    justify-content: flex;
    align-items: center;
}

.gmc__navbar-links_container p, 
.gmc__navbar-sign p, 
.gmc__navbar-menu_container p {
    color: var(--color-text-primary);
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;

} 

.gmc__navbar-links_container p a.active, 
.gmc__navbar-sign p a.active, 
.gmc__navbar-menu_container p a.active {
    color: var(--color-primary);
}

.gmc__navbar-sign p a {
    padding: 1rem 2rem;
}

.gmc__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
    
}

.gmc__navbar-menu svg {
    cursor: pointer;
    fill: var(--color-text-primary);
}

.gmc__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: #fff;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 250px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
}

.gmc__navbar-menu_container p {
    margin: 1rem 0;
}

.gmc__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .gmc__navbar-links_container {
        display: none;
    }

    .gmc__navbar-menu {
        display: flex;
    }

    .gmc__navbar-menu_container-links-sign {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .gmc__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gmc__navbar {
        padding: 2rem;
    }

    .gmc__navbar.sticky-bar {
        padding: 1rem 2rem;
    }
    
    .gmc__navbar-sign {
        display: none;
    }

    .gmc__navbar-menu_container {
        top: 20px;
    }

    .gmc__navbar-menu_container-links-sign {
        display: block;
    }
}

@media screen and (max-width: 550px) {
    .gmc__navbar.sticky-bar {
        padding: .5rem 2rem;
    }

    .gmc__navbar.sticky-bar .gmc__navbar-links_logo img {
        width: 40px;
    }
}