.gmc__faq-background {
    background: linear-gradient(0deg, rgba(25, 118, 210, 0) -4.42%, rgba(25, 118, 210, 0.08) 100%);
    padding-top: 8rem !important;
}

.gmc__faq__header .section__heading {
    font-size: 40px;
    line-height: 56px;
}

.gmc__questions__cover {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

@media screen and (max-width: 1379px) {
    .gmc__questions__cover {
        width: 100%;
        gap: 1rem;
    }
}

@media screen and (max-width: 490px) {
    
}
