@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');


:root {
    --font-family-headers: 'PT Serif', serif;
    --font-family: 'Inter', serif;

    --color-primary: #46AFDE;
    --color-text-primary: #170F49;
    --color-text-secondary: #3C5366;
    --color-text-subtitle: #3885B5;
    --color-cta: #40A1CC;
    --color-footer: #2B2D2F;
    --color-bg-grey: #F7F7FB;
}