.gmc__pricing__plan   {
    padding: 40px;
    background: #F2F2F6;
    transition: .5s background ease-in;
    border-radius: 16px;
}

.gmc__pricing__plan:hover, .gmc__pricing__plan.active   {
    background: var(--color-text-primary);
}

.gmc_pricing_plan_wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.gmc__pricing__plan_cover {
    height: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    background: #fff;
    border-radius: 16px;
    padding: 40px;
}

.gmc__pricing__plan__subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: var(--color-text-secondary);
}

.gmc__pricing__plan__title {
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    color: #000624;
}

.gmc__pricing__plan__title span{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-text-secondary);
}

.gmc__pricing__plan__desc {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-secondary);
}

.gmc__pricing__plan a {
    display: block;
    text-align: center;
    padding: 1.4rem 1rem;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    background: linear-gradient(0deg, #68C9F4 0%, #50ABED 100%);
    box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    color: #fff;
    text-transform: capitalize;
}

@media screen and (max-width: 490px) {
    .gmc__pricing__plan {
        padding: 20px;
    }
    .gmc__pricing__plan_cover {
        padding: 20px;
    }
    .gmc__pricing__plan__image img {
        width: 48px;
        height: 48px;
    }
    .gmc__pricing__plan__subtitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
    }

    .gmc__pricing__plan__title {
        font-size: 24px;
        line-height: 32px;
    }

    .gmc__pricing__plan__desc {
        font-size: 14px;
        line-height: 24px;
    }

    .gmc__pricing__plan a {

    }
}