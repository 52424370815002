.gmc__homecare-background {
    background: linear-gradient(180deg, rgba(25, 118, 210, 0) -4.42%, rgba(25, 118, 210, 0.08) 100%);
    padding-top: 8rem !important;
}

.gmc__homecare__header {
    display: flex;
    align-items: center;
    gap: 5rem;
}

.gmc__homecare__header__cover {
    flex-basis: 50%;
}

.gmc__homecare__header .section__heading {
    font-size: 40px;
    line-height: 56px;
}

.gmc__homecare__header .section__desc {
    font-size: 18px;
    line-height: 32px;
    margin: 1.5rem 0;
}

.gmc__homecare__header .primary__button {
    font-size: 18px;
    line-height: 32px;
}

.gmc__homecare__checklist {
    padding: 2rem;
    background: #FFFFFF;
    box-shadow: 0px 15px 50px rgba(115, 122, 145, 0.08);
    border-radius: 16px;
}

.gmc__homecare__checklist__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3rem;
    margin: 1rem 0;
}

.gmc__homecare__checklist__item p {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-secondary);
}

.gmc__homecare__checklist__item p br {
   display: none;
}

@media screen and (max-width: 1359px) {
    .gmc__homecare__header {
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
    }
}


@media screen and (max-width: 490px) {

}