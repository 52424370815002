.default__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 624px;
    max-height: calc(100vh - 50px);
    overflow: auto;
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(232, 237, 250, 0.2);
    border-radius: 16px;
    padding: 2rem;
}


.close__button {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #F5F5F7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .4s;
}

.close__button:hover {
    background: #e4e4e7;
}

.close__button svg {
    position: relative;
    z-index: 100;
}


@media screen and (max-width: 1359px) {
    .default__modal {
        width: calc(100% - 40px);
    }
}


@media screen and (max-width: 490px) {

}