.gmc__pricing-background {
    background: linear-gradient(0deg, rgba(25, 118, 210, 0) -4.42%, rgba(25, 118, 210, 0.08) 100%);
    padding-top: 8rem !important;
}

.gmc__pricing__header {
    text-align: center;
}


.gmc__pricing-plans {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.5rem;
}

.gmc__pricing-plans-item {
    flex-basis: 445px;
}

@media screen and (max-width: 1500px) {
    .gmc__pricing-plans {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 1080px) {
    .gmc__pricing-plans {
        grid-template-columns: 1fr;
    }
}




