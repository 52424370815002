.gmc__about__gradient {
    background: linear-gradient(0deg, rgba(25, 118, 210, 0) -4.42%, rgba(25, 118, 210, 0.08) 100%);
    padding-top: 8rem !important;
}

.gmc__about__header {
    display:  flex;
    align-items: center;
    gap: 10rem;
}

.gmc__about__header .section__heading {
    font-size: 24px;
    line-height: 40px;
}


.gmc__about__features{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 1rem 5rem;
    grid-template-areas: 
    "header item1 item2"
    "item3 item4 item2"
    ". . ."; 
}

.gmc__about__features__header { grid-area: header; }
.gmc__about__feature:nth-child(2) { grid-area: item1;}
.gmc__about__feature:nth-child(3) { grid-area: item2;}
.gmc__about__feature:nth-child(4) { grid-area: item3;}
.gmc__about__feature:nth-child(5) { grid-area: item4;}

.gmc__about__feature:not(:first-child) h4 {
    font-size: 24px;
    line-height: 32px;
    margin: 1rem 0;
}

.gmc__about__feature:not(:first-child) p {
    font-size: 16px;
    line-height: 29px;
}


@media screen and (max-width: 1050px) {
    .gmc__about__header {
        flex-direction: column;
        gap: 2rem;
    }

    .gmc__about__features {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: 
        "header item1"
        "item2 item3"
        "item4 ."; 
        gap: 2rem 2rem;
    }
}

@media screen and (max-width: 650px) {
    .gmc__about__features {
        display: flex;
        flex-direction: column;
        gap: 2;
    }
}

@media screen and (max-width: 490px) {
    .gmc__about__header {
        flex-direction: column;
        gap: 2rem;
    }

    .gmc__about__feature:not(:first-child) h4 {
        font-size: 20px;
        line-height: 25px;
    }
    
    .gmc__about__feature:not(:first-child) p {
        font-size: 14px;
        line-height: 22px;
    }
    
}


