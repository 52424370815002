* {
    box-sizing: border-box;
    padding:  0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: var(--font-family);
}

h1,h2,h3,h4,h5,h6 {
    font-family: var(--font-family-headers);
    color: var(--color-text-primary);
    font-weight: 800;
}

body {
    background: #fff;
}

a {
    color: unset;
    text-decoration: none;
}


.button__primary {
    display:  inline-block;
    padding: 1.4rem;
    box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    background: var(--color-primary);
    color: #fff;
    transition: background 1s;
    outline: none;
    border: none;
}

.button__primary:hover {
    background: var(--color-text-primary);
}

.section__quote {
    position: relative;
    padding-left: 2rem;
}

.section__quote::before {
    content: "";
    position: absolute;
    width: 3px;
    background: var(--color-text-subtitle);
    height: 128px;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
    border-radius: 100px;
}

.section__quote p {
    font-size: 18px;
    line-height: 32px;
    color: var(--color-text-secondary);
}

@media screen and (max-width: 490px) {
    .button__primary {
        padding: 1rem;
        font-size: 12;
        line-height: 16px;
    }
}

.section__padding {
    padding: 5rem 10rem;
}

.section__margin {
    margin: 5rem 10rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

.section__subtitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: var(--color-text-subtitle);
    text-transform: uppercase;
    letter-spacing: 2px;
}

.section__heading {
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 43px;
    color: var(--color-text-primary);
    letter-spacing: 0.2px;
    margin: 1.3rem 0;
}

.section__desc {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-text-secondary);
}

.section__bg__lines {
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        padding: 4rem;
    }
    
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin {
        padding: 4rem 2rem;
    }
    
}

@media screen and (max-width: 490px) {
    .section__heading {
        font-size: 21px;
        line-height: 32px;
    }

    .section__subtitle {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 2px;
    }

    .section__desc {
        font-size: 16px;
        line-height: 26px;
    }
}