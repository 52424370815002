.gmc__contact-us-background {
    background: linear-gradient(0deg, rgba(25, 118, 210, 0) -4.42%, rgba(25, 118, 210, 0.08) 100%);
    padding-top: 8rem !important;
}

.gmc__contact-us__header {
    text-align: center;
}

.gmc__contact-us__header .section__heading {
    font-size: 40px;
    line-height: 56px;
}

.gmc__contact-us__cover {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.gmc__contact__form  {
    width: 50%;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(232, 237, 250, 0.2);
    border-radius: 16px;
  
}

.form__row {
    padding: 1rem 0;
}

.gmc__contact__form label {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text-primary);
    margin-bottom: .4rem;
}
.gmc__contact__form input[type="text"], .gmc__contact__form textarea {
    display: block;
    width: 100%;
    background: #F7F9FC;
    border: 1px solid #EDF2F7;
    border-radius: 4px;
    padding: 1rem;
    color: #3C5366;
    outline: none;
}

.form__buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem;
}

.form__buttons .button__primary {
    padding: 1rem 3rem !important;
}



@media screen and (max-width: 1379px) {
    .gmc__contact-us__cover {
        width: 100%;
        padding: 0 1rem;
        gap: 1rem;
    }

    .gmc__contact__form {
        width: calc(100% - 4rem);
    }
}

@media screen and (max-width: 490px) {
    .gmc__contact__form {
        width: calc(100% - 2rem);
    }
}
